var render, staticRenderFns
import script from "./sm-improved-toolbar.vue?vue&type=script&lang=js&"
export * from "./sm-improved-toolbar.vue?vue&type=script&lang=js&"
import style0 from "./sm-improved-toolbar.vue?vue&type=style&index=0&id=3682b864&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3682b864",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src551672837/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3682b864')) {
      api.createRecord('3682b864', component.options)
    } else {
      api.reload('3682b864', component.options)
    }
    
  }
}
component.options.__file = "src/views/components/sm-improved-toolbar.vue"
export default component.exports